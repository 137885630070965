<template>
	<div>
    <Breadcrumbs :items="crumbs" />
    <div class="white">
      
      <v-container>
        <div class="display-3">
          <span class="thin">Licensing</span>
        </div><br />
        <div class="display-4">
          <span class="thick">Opportunities</span>
        </div>
      </v-container>
    </div>

    <div class="grey darken-4 white--text">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Put our technology to work for you.</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">User-friendly dashboard designed for ease of use for marketing teams</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Patented software allows licensees to build proprietary interface unique to its customers</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Licensees can collect, analyze and disseminate information all in real time</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Provides a multidimensional approach to marketing</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Licensing',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>